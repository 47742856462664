exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aboutus-js": () => import("./../../../src/pages/aboutus.js" /* webpackChunkName: "component---src-pages-aboutus-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-fmcg-delivery-software-js": () => import("./../../../src/pages/fmcg-delivery-software.js" /* webpackChunkName: "component---src-pages-fmcg-delivery-software-js" */),
  "component---src-pages-food-and-beverage-distributor-js": () => import("./../../../src/pages/food-and-beverage-distributor.js" /* webpackChunkName: "component---src-pages-food-and-beverage-distributor-js" */),
  "component---src-pages-grow-delivery-business-from-covid-19-js": () => import("./../../../src/pages/grow-delivery-business-from-covid-19.js" /* webpackChunkName: "component---src-pages-grow-delivery-business-from-covid-19-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-laundry-delivery-software-js": () => import("./../../../src/pages/laundry-delivery-software.js" /* webpackChunkName: "component---src-pages-laundry-delivery-software-js" */),
  "component---src-pages-medicine-pharmacy-delivery-software-js": () => import("./../../../src/pages/medicine-pharmacy-delivery-software.js" /* webpackChunkName: "component---src-pages-medicine-pharmacy-delivery-software-js" */),
  "component---src-pages-on-demand-courier-parcel-delivery-software-js": () => import("./../../../src/pages/on-demand-courier-parcel-delivery-software.js" /* webpackChunkName: "component---src-pages-on-demand-courier-parcel-delivery-software-js" */),
  "component---src-pages-online-plants-delivery-software-js": () => import("./../../../src/pages/online-plants-delivery-software.js" /* webpackChunkName: "component---src-pages-online-plants-delivery-software-js" */),
  "component---src-pages-online-water-delivery-software-js": () => import("./../../../src/pages/online-water-delivery-software.js" /* webpackChunkName: "component---src-pages-online-water-delivery-software-js" */),
  "component---src-pages-retail-ecommerce-delivery-software-js": () => import("./../../../src/pages/retail-ecommerce-delivery-software.js" /* webpackChunkName: "component---src-pages-retail-ecommerce-delivery-software-js" */),
  "component---src-pages-workforce-management-software-js": () => import("./../../../src/pages/workforce-management-software.js" /* webpackChunkName: "component---src-pages-workforce-management-software-js" */),
  "component---src-templates-blog-author-js": () => import("./../../../src/templates/blog-author.js" /* webpackChunkName: "component---src-templates-blog-author-js" */),
  "component---src-templates-blog-categories-js": () => import("./../../../src/templates/blog-categories.js" /* webpackChunkName: "component---src-templates-blog-categories-js" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-best-route-optimization-software-for-small-and-mid-sized-fleet-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/best-route-optimization-software-for-small-and-mid-sized-fleet/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-best-route-optimization-software-for-small-and-mid-sized-fleet-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-challenges-of-last-mile-delivery-for-food-delivery-business-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/challenges-of-last-mile-delivery-for-food-delivery-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-challenges-of-last-mile-delivery-for-food-delivery-business-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-challenges-solution-for-successful-courier-business-and-customer-satisfaction-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/challenges-solution-for-successful-courier-business-and-customer-satisfaction/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-challenges-solution-for-successful-courier-business-and-customer-satisfaction-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-comprehensive-guide-on-last-mile-delivery-solution-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/comprehensive-guide-on-last-mile-delivery-solution/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-comprehensive-guide-on-last-mile-delivery-solution-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-delivery-tracking-apps-changing-logistics-industry-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/delivery-tracking-apps-changing-logistics-industry/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-delivery-tracking-apps-changing-logistics-industry-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-food-delivery-safety-in-covid-19-corona-pandemic-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/food-delivery-safety-in-covid-19-corona-pandemic/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-food-delivery-safety-in-covid-19-corona-pandemic-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-grow-your-water-delivery-service-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/grow-your-water-delivery-service/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-grow-your-water-delivery-service-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-how-on-demand-industry-can-withstand-the-coronavirus-threat-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/how-on-demand-industry-can-withstand-the-coronavirus-threat/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-how-on-demand-industry-can-withstand-the-coronavirus-threat-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-how-to-sustain-your-business-during-coronavirus-pandemic-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/how-to-sustain-your-business-during-coronavirus-pandemic/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-how-to-sustain-your-business-during-coronavirus-pandemic-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-impact-of-drone-delivery-on-global-logistics-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/impact-of-drone-delivery-on-global-logistics/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-impact-of-drone-delivery-on-global-logistics-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-last-mile-delivery-better-customer-experience-in-retail-industry-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/last-mile-delivery-better-customer-experience-in-retail-industry/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-last-mile-delivery-better-customer-experience-in-retail-industry-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-last-mile-delivery-cost-to-increase-profit-margins-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/last-mile-delivery-cost-to-increase-profit-margins/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-last-mile-delivery-cost-to-increase-profit-margins-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-last-mile-delivery-during-and-after-lockdown-with-fixlastmile-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/last-mile-delivery-during-and-after-lockdown-with-fixlastmile/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-last-mile-delivery-during-and-after-lockdown-with-fixlastmile-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-last-mile-delivery-for-ecommerce-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/last-mile-delivery-for-ecommerce/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-last-mile-delivery-for-ecommerce-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-last-mile-delivery-optimization-for-food-delivery-industry-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/last-mile-delivery-optimization-for-food-delivery-industry/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-last-mile-delivery-optimization-for-food-delivery-industry-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-last-mile-delivery-solution-in-logistics-startups-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/last-mile-delivery-solution-in-logistics-startups/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-last-mile-delivery-solution-in-logistics-startups-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-last-mile-delivery-solutions-disrupt-logistics-business-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/last-mile-delivery-solutions-disrupt-logistics-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-last-mile-delivery-solutions-disrupt-logistics-business-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-reduce-last-mile-delivery-costs-in-your-business-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/reduce-last-mile-delivery-costs-in-your-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-reduce-last-mile-delivery-costs-in-your-business-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-route-optimization-enhances-last-mile-delivery-operations-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/route-optimization-enhances-last-mile-delivery-operations/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-route-optimization-enhances-last-mile-delivery-operations-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-route-optimization-software-for-your-pharmacy-delivery-companies-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/route-optimization-software-for-your-pharmacy-delivery-companies/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-route-optimization-software-for-your-pharmacy-delivery-companies-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-scaling-up-courier-and-parcel-delivery-business-by-using-mobile-app-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/scaling-up-courier-and-parcel-delivery-business-by-using-mobile-app/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-scaling-up-courier-and-parcel-delivery-business-by-using-mobile-app-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-solve-last-mile-delivery-problems-with-fixlastmile-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/solve-last-mile-delivery-problems-with-fixlastmile/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-solve-last-mile-delivery-problems-with-fixlastmile-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-technology-solutions-to-overcome-covid-19-logistics-challenges-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/technology-solutions-to-overcome-covid-19-logistics-challenges/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-technology-solutions-to-overcome-covid-19-logistics-challenges-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-what-will-the-route-optimization-software-market-size-and-the-growth-rate-be-in-2025-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/what-will-the-route-optimization-software-market-size-and-the-growth-rate-be-in-2025/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-what-will-the-route-optimization-software-market-size-and-the-growth-rate-be-in-2025-index-mdx" */)
}

